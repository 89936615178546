import React from "react"
import Navigation from "../components/nav-en-construccion"
import Footer from "../components/footer"
import "../styles/main.scss"
/*const ListLink = props => (
  <li style={{ display: `inline-block`, marginRight: `1rem` }}>
    <Link to={props.to}>{props.children}</Link>
  </li>
)*/

export default ({ children }) => (
  <div id="wrap">
    <Navigation />
    <div id="main-content">{children}</div>
    <Footer />
  </div>
)