import React from "react"
import  Layout from "../components/en-construccion-layout"
import SEO from "../components/seo"
import sitioEnConstruccion from "../images/juan-compu.svg"
export default () => (
  <Layout>
    <SEO title="Sitio web personal"/>
    <h1>Sitio web en construcción</h1>
    <h2>¡Qué dejecxión!</h2>
   <section className="bloque1">
      <div className="ilustracion">
        <img src={sitioEnConstruccion} alt="Sitio en construcción"></img>
      </div>
      <div className="description">
      <p>
         Gracias por visitar mi sitio web pero por el momento no he podido dedicarle el tiempo necesario para su desarrollo (estoy aprendiendo a desarrollarlo desde cero lo cual me lleva mucho más tiempo de lo normal)
        </p>
      </div>
    </section>
  </Layout>
)
